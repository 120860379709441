// Fonts
@import url(https://Fonts.googleapis.com/css?family=Raleway:300,400,600);
// Variables
@import "variables";
// Bootstrap 3
// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
// Bootstrap 4
@import "node_modules/bootstrap/scss/bootstrap";
// buttons
.btn-primary {
    color: $font-main-contrast;
    border-color: $main-color !important;
    background-color: $main-color !important;
    &:hover {
        color: $main-color;
        background-color: white !important;
        border-color: $main-color;
    }
}

.btn-outline-theme {
    @extend .btn-outline-secondary;
    border-color: $font-main-contrast;
    color: $font-main-contrast;
    text-transform: uppercase;
    &:hover {
        color: $main-color;
        background: $font-main-contrast;
        border-color: $main-color;
    }
}

.btn-theme {
    @extend .btn-secondary;
    font-family: 'Helvetica';
    font-weight: bold;
    color: $main-color !important;
    border-color: $font-main-contrast;
    background-color: $font-main-contrast;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
        color: $font-main-contrast !important;
        background: $main-color;
        border-color: $font-main-contrast;
        cursor: pointer;
    }
}

.btn-footer {
    padding: 1rem;
    border-radius: 20px;
    font-size: 0.5rem;
    margin-left: auto;
    margin-right: auto;
}

.btn-theme-invert {
    @extend .btn-secondary;
    font-family: 'Helvetica';
    font-weight: bold;
    color: $font-main-contrast !important;
    border-color: $main-color;
    background-color: $main-color;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
        color: $font-main-contrast !important;
        background: $main-color;
        border-color: $font-main-contrast;
        cursor: pointer;
    }
}

// Form elements
html,
body {
    overflow-x: hidden;
}

body {
    position: relative
}

a {
    color: $main-color;
}

.correction {
    margin-left: -6px;
    margin-right: -6px;
}

.custom-radio .custom-control-input:checked~.custom-control-indicator {
    background-color: $main-color !important;
}

.form-control {
    border-radius: 0px;
    color: black;
    &:focus {
        color: black;
    }
}

.form-group.center {
    margin: 0 auto;
    text-align: center;
    width: inherit;
    display: inline-block;
}

.custom-control-input:checked~.custom-control-indicator {
    background-color: $main-color !important;
}

label {
    color: $main-color;
    font-style: italic;
}

span.custom-control-description {
    color: #292b2c;
}

p {
    //padding-right: 20px;
}

// header
header {
    .logo-text {
        width: 100%;
    }
    background: $main-color;
    &.main {
        height: $header-height;
        margin-bottom: 7rem;
    }
    img.shirt-logo {
        top: 28px;
        width: 100%;
        height: 100%;
    }
}

footer {
    background: $main-color;
    // min-height: 20rem;
    // position: absolute;
    // bottom: 0;
    // width: 100%;
    h2 {
        color: $font-main-contrast;
        text-align: center;
        font-size: 1.5rem;
    }
    p {
        text-align: center;
        color: $font-main-contrast;
        font-size: 0.8rem;
    }
    a {
        width: 100%;
        text-align: center;
        color: $font-main-contrast;
        text-decoration: underline;
        &:hover {
            color: $gray;
        }
    }
    .socials {
        height: 20px;
        width: auto;
    }
}

// Nav
@mixin nav-link() {
    color: rgba(255, 255, 255, 1) !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    font-size: 1rem;
    text-transform: uppercase;
}

.navbar {
    // padding: 0rem;
}

.navbar-text {
    padding-top: .225rem !important;
}

.navbar-brand {
    width: 126px;
    //background-color: #01843e;
    //padding: 3rem !important;
    margin-top: 2rem;
    img {
        width: 100%;
        height: auto;
        float: left;
        clear: left;
    }
    span.logo-text {
        padding-top: 3px;
        color: $font-main-contrast;
        font-size: 9px;
        text-align: center;
        float: left;
        clear: left;
    }
    padding: 0rem;
}

.nav-item {
    //color: rgba(255, 255, 255, 1) !important;
    .nav-link {
        @include nav-link();
    }
    &.active {
        .nav-link {
            @include nav-link();
            border-bottom: 0.3rem solid $font-main-contrast;
        }
    }
}

@mixin h() {
    color: $main-color;
    font-size: 1rem;
    text-transform: uppercase;
    font-family: 'Helvetica';
    font-weight: bold;
    text-align: center;
}

h1 {
    @include h();
    text-align: left;
}

h2 {
    @include h();
    font-size: 1rem;
}

// Content
.block {
    background-color: $gray;
    h1 {
        @include h();
    }
    h2 {
        @include h();
        font-size: 1rem;
    }
    p {
        font-family: 'Helvetica';
        font-size: 0.8rem;
        &.center {
            text-align: center;
        }
    }
}

article {
    background-color: $gray;
    background-clip: content-box;
    header {
        background: transparent;
        h2 {
            color: $main-color;
            font-size: 1rem;
            text-transform: uppercase;
            font-family: 'Helvetica';
            font-weight: bold;
            text-align: left;
        }
        time {
            color: #292b2c;
            font-size: 0.6rem;
        }
    }
    div,
    p {
        font-family: 'Helvetica';
        font-size: 0.8rem;
    }
    img {
        height: 100%;
        width: 100%;
    }
    a {
        color: $main-color;
        &:hover {
            color: darken($main-color, 15);
        }
    }
}

article.newsDetaild {
    p {
        width: 100%;
    }
}

.paddingChildP {
    p {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

//Media
section#videos {
    header {
        h2 {
            height: 68px;
            text-align: center;
            overflow: hidden;
        }
    }
    article {
        img {
            width: 100%;
            height: auto;
        }
    }
    iframe {
        width: 100%;
    }
}

// Partners
section#partners {
    .text {
        background-color: $gray;
        background-clip: content-box;
        img {
            width: 100%;
            height: auto;
        }
        h2 {
            color: $main-color;
            font-size: 1rem;
            text-transform: uppercase;
            font-family: 'Helvetica';
            font-weight: bold;
            text-align: center;
        }
        p {
            text-align: center;
            font-family: 'Helvetica';
            font-size: 0.8rem;
        }
    }
    img.partner-logo {
        width: 50%;
        height: auto;
    }
}

div#map {
    display: block;
    height: 200px !important;
    width: 100% !important;
}

@mixin mobile() {
    .logo-text {
        display: none;
    }
    .navbar-brand {
        width: 40px;
        margin-top: 20px;
        margin-left: 20px;
    }
    .navbar-nav {
        margin-top: 20px;
    }
    .navbar-collapse.collapse.in {
        background-color: $main-color;
        z-index: 99999;
        display: block;
    }
    .navbar-text {
        display: none;
    }
    .navbar-toggler-right {
        margin-top: 20px;
    }
    .navbar-light .navbar-toggler {
        border-color: white;
    }
    .navbar-preheader .navbar-toggle:hover .icon-bar {
        background-color: white;
    }
    .fa.fa-bars {
        padding: 4px;
        color: white;
    }
    header.main {
        height: 23rem;
    }
    header p.slogan {}
    header p.date {
        top: 0px;
        font-size: 1.2rem;
    }
    header img.shirt-logo {
        top: 40px;
    }
    .pl-1 {
        padding-left: 1.5rem !important;
    }
    .mobile-subscribe {
        display: block !important;
    }
}

.mobile-subscribe {
    right: 100px;
    display: none;
}


/* Medium Devices, Desktops */

@media only screen and (max-width: 1024px) {
    .nav-link {
        font-size: 0.8rem !important;
    }
}

@media only screen and (max-width: 992px) {
    @include mobile();
    .nav-item .nav-link {
        padding-right: .5rem !important;
        padding-left: .5rem !important;
    }
}


/* Small Devices, Tablets */

@media only screen and (max-width: 768px) and (orientation: landscape) {
    @include mobile();
    header img.shirt-logo {
        top: 0px;
        width: 65% !important;
    }
}

@media only screen and (max-width: 768px) {
    @include mobile();
    .mobile-subscribe {
        position: absolute;
        top: 0px;
    }
    header.main {
        height: auto !important;
        padding-bottom: 20px;
    }
}


/* Extra Small Devices, Phones */

@media only screen and (max-width: 480px) {
    @include mobile();
    .mobile-subscribe {
        position: absolute;
        top: 0px;
        left: 50%;
        margin-left: -80px;
    }
    header.main {
        height: auto !important;
    }
    header img.shirt-logo {
        top: 68px;
    }
}


/* Custom, iPhone Retina */

@media only screen and (max-width: 320px) {
    @include mobile();
    .navbar-brand {
        margin-left: 0px;
    }
    .navbar-toggler-right {
        margin-right: -15px;
    }
    .mobile-subscribe {
        position: absolute;
        top: 0px;
        left: 50%;
        margin-left: -65px;
    }
    header.main {
        height: auto !important;
    }
    header img.shirt-logo {
        top: 52px;
    }
}