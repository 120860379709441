$main-color: #0066ad;
$font-main-contrast: #fff;
$gray: #f3f3f3;
$header-height: 13rem;
@font-face {
    font-family: 'qtypesquare-boldregular';
    src: url('../font/qtypesquare-bold-webfont.woff2') format('woff2'), url('../font/qtypesquare-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'qtypesquare-bookregular';
    src: url('../font/qtypesquare-book-webfont.woff2') format('woff2'), url('../font/qtypesquare-book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}